div.switch-symbols {
   display: flex;
}

button.switch-group {
   border-width: 0.1vw;
   font-weight: bold;
   padding: 0vw 2vw;
}

button.switch-setting {
   margin-left: auto;
   font-weight: bold;
   border-width: 0.1vw;
   padding: 0vw 2vw;
}


table {
   width: 100%; 
   background-color: black;
   color: white;
}

th {
   padding: 0.2vw 0vw;
   border-width: 0.1vw;
   border-color: white;
   font-size: 1.2vw;
}

tr {
   border-width: 0.1vw;
   border-color: white;
   font-size: 1vw;
}

td {
   padding: 0.0vw 0vw;
   border-width: 0.1vw;
   border-color: white;
   font-size: 1vw;
   text-align: center;
}

td.symbol {
   font-size: 3vw;
}

td.last {
   font-size: 4.4vw;
}

div.high {
   color: lime;
   font-size: 2vw;
}

div.low {
   color: red;
   font-size: 2vw;
}

td.change {
   font-size: 3vw;
}

td.higher {
   color: lime;
}

td.lower {
   color: red;
}

td.percent {
   font-size: 3vw;
}


.modal-background {
   position: fixed;
   width: 100vw;
   height: 100vh;
   background-color: #00000050;
}

.flex-background {
   display: flex;
   width: 100vw;
   height: 100vh;
}
.modal {
   width: 24rem;
   margin: auto;
   background-color: white;
   border-radius: 1rem;
   padding: 1rem;
   z-index: 10;
}

.modal-header {
   font-weight: bold;
   display: flex;
   justify-content: space-between;
}

.modal-body {
   margin: 0.5rem 0rem;
}

.input-header {
   display: flex;
}
.input-title {
   flex: 1 1 0%;
   width: 100%;
}

.input-row {
   display: flex;
   margin: 0.25rem;
}

.input-group {
   flex: 1 1 0%;
   display: flex;
   margin: 0rem 0rem;
}
.input-group.left {
   margin-right: 0.25rem;
}
.input-group.right {
   margin-left: 0.25rem;
}


.show-symbol {
   border-width: 0.1rem;
   padding: 0 0.5rem;
}

.input-symbol {
   border-width: 0.1rem;
   padding: 0 0.25rem;
   flex: 1 1 0%;
   width: 100%;
}

.delete-symbol {
   font-weight: bold;
   border-width: 0.1rem;
   padding: 0 0.5rem;
}

.input-compare {
   border-width: 0.1rem;
   padding: 0 0.25rem;
   flex: 1 1 0%;
   width: 100%;
}

.clear-compare {
   font-weight: bold;
   border-width: 0.1rem;
   padding: 0 0.5rem;
}


.modal-footer {
   display: flex;
   justify-content: space-between;
}

button.add-symbol {
   padding: 0.25rem 1rem;
   background-color: green;
   color: white;
   border-radius: 0.5rem;
}

button.save-symbol {
   padding: 0.25rem 1rem;
   background-color: midnightblue;
   color: white;
   border-radius: 0.5rem;
}

/* {`fixed inset-0 bg-gray-800 bg-opacity-50 overflow-y-auto h-full w-full`}> */